import React from "react"
import Box from "./box"
import PropTypes from "prop-types"

const RotateLetter = ({ bg, children, size, fontSize, ...restProps }) => {
  /* random number between 1 and 4 */
  const randomNumber = Math.floor(Math.random() * (4 - 1 + 1) + 1)

  /* angles for rotated box */
  const angles = {
    1: "-2deg",
    2: "-1deg",
    3: "1deg",
    4: "2deg",
  }

  const randomAngle = angles[randomNumber]

  return (
    <Box
      sx={{
        bg: bg,
        width: size,
        height: size,
        borderRadius: "md",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        color: "background",
        fontFamily: "sans",
        fontWeight: "bold",
        fontSize: fontSize,
        transform: `rotate(${randomAngle})`,
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

RotateLetter.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.number,
  fontSize: PropTypes.number,
}

RotateLetter.defaultProps = {
  bg: "contrast",
  size: 16,
  fontSize: 8,
}

export default RotateLetter
