import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Blockquote from "../../../components/blockquote"
import Box from "../../../components/box"
import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Poem from "../../../components/poem"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import Trivia from "../../../components/trivia"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopOktoberPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/oktober/materialien.jpg" }
      ) {
        ...largeImage
      }
      postkarte: file(relativePath: { eq: "postkarte-herbst.pdf" }) {
        publicURL
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/oktober/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/oktober/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      baum1: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-1.jpg" }
      ) {
        ...largeImage
      }
      baum2: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-2.jpg" }
      ) {
        ...largeImage
      }
      baum3: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-3.jpg" }
      ) {
        ...largeImage
      }
      baum4: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-4.jpg" }
      ) {
        ...largeImage
      }
      baum5: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-5.jpg" }
      ) {
        ...largeImage
      }
      baum6: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-6.jpg" }
      ) {
        ...largeImage
      }
      baum7: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-7.jpg" }
      ) {
        ...largeImage
      }
      baum8: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-8.jpg" }
      ) {
        ...largeImage
      }
      baum9: file(
        relativePath: { eq: "workshops/kalender/oktober/baum-9.jpg" }
      ) {
        ...largeImage
      }
      garten: file(
        relativePath: { eq: "workshops/kalender/oktober/garten.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 640, height: 900, layout: CONSTRAINED)
        }
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-oktober">
      <Seo
        title="Oktober"
        description="Um den Sommer willkommen zu heißen, schreiben wir für ihn ein Gedicht in Form eines Akrostichons. Ein Akrostichon ist ein Gedicht, bei dem die Anfangsbuchstaben der Verse ein eigenes Wort ergeben. Passend zur Jahreszeit haben wir uns das Wort S-O-M-M-E-R ausgesucht."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "Oktober",
              link: "/workshops/kalender/oktober",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>Oktober</PageTitle>
            <Paragraph dropcap={true}>
              Oktober färbt die Natur mit einer Palette schönster Gelb-,
              Orange-, Grün- und Braun-Töne. Wenn der Wind durch die Bäume weht,
              fallen die Blätter herunter und bilden einen weichen Teppich unter
              den Füßen der Spaziergänger. Das wollen wir auch im Kalender
              festhalten.
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Image
                image={data.garten.childImageSharp.gatsbyImageData}
                alt="Blick in den Garten des Hölderlinturms"
              />
              <Paragraph>
                Hölderlin hat die Blätter der Bäume und ihre Farben in seinem
                Gedicht ›Die Wanderung‹ so beschrieben:
              </Paragraph>
              <Paragraph>
                <Blockquote>
                  <p>»Und Bäume genug, weißblühend und rötlich,</p>
                  <p>Und dunklere, wild, tiefgrünenden Laubs voll«</p>
                </Blockquote>
              </Paragraph>
              <Paragraph>
                Und in seinem Gedicht ›Der Herbst.‹ schreibt er über den Klang
                des Herbstes
              </Paragraph>
              <Paragraph>
                <Blockquote>
                  <p>»Das Erdenrund ist so geschmükt, und selten lärmet</p>
                  <p>Der Schall durchs offne Feld</p>
                  <p>[...]</p>
                  <p>die Lüffte wehen</p>
                  <p>Die Zweig’ und Äste durch mit frohem Rauschen«</p>
                </Blockquote>
              </Paragraph>
              <Trivia>
                Der Punkt hinter dem Gedichttitel ist kein Versehen. Er stammt
                von Hölderlin selbst. Man kann sich richtig vorstellen, wie er
                dabei noch einmal aus dem Fenster gesehen, tief Luft geholt und
                Ideen gesammelt hat, bevor er mit den ersten Versen seines
                Gedichts begann.
              </Trivia>
              <Paragraph>
                Haben dich diese Verse bereits in die herbstliche Landschaft
                hineinversetzt? Dann können wir jetzt loslegen und mit der
                Gestaltung deiner Kalenderseite beginnen.
              </Paragraph>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Stack>
                <Heading as="h2" level={2}>
                  Du brauchst...
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      <TextLink href={data.postkarte.publicURL}>
                        Hölderlins Herbstgedicht
                      </TextLink>
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Eine Unterlage und eine Malschürze oder ein Oberteil, das
                      dreckig werden darf
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Ein Blatt Papier zum Malen
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-4">
                    <Text as="span" size={4}>
                      Einen Pinsel
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-5">
                    <Text as="span" size={4}>
                      Tempera-, Acryl- oder Wasserfarben in braun, gelb, orange,
                      rot und grün. Wenn du willst, kannst du auch gerne noch
                      mehr Farben hinzufügen.
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-6">
                    <Text as="span" size={4}>
                      Eine Schere und einen Klebestift
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-7">
                    <Text as="span" size={4}>
                      Deine Hände und Finger
                    </Text>
                  </Checkbox>
                </Stack>
                <Image
                  image={data.materialien.childImageSharp.gatsbyImageData}
                  alt="Die Anleitung zum Kalenderblatt und eine orangene Postkarte mit Hölderlins Herbstgedicht"
                />
              </Stack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <Stack space={3}>
                  <StepsBox step={1}>
                    <Paragraph>
                      Lies dir Hölderlins Gedicht zunächst noch einmal im Ganzen
                      durch.
                    </Paragraph>
                  </StepsBox>
                  <Box
                    sx={{
                      borderWidth: 4,
                      borderStyle: "solid",
                      borderColor: "border",
                      p: [6, 6, 8],
                    }}
                  >
                    <Stack space={6}>
                      <Heading as="div" level={5}>
                        Der Herbst.
                      </Heading>
                      <Poem size={[3, 3, 4]}>
                        <p>Das Glänzen der Natur ist höheres Erscheinen,</p>
                        <p>Wo sich der Tag mit vielen Freuden endet,</p>
                        <p>Es ist das Jahr, das sich mit Pracht vollendet,</p>
                        <p>Wo Früchte sich mit frohem Glanz vereinen.</p>
                        <br />
                        <p>Das Erdenrund ist so geschmükt, und selten lärmet</p>
                        <p>Der Schall durchs offne Feld, die Sonne wärmet</p>
                        <p>Den Tag des Herbstes mild, die Felder stehen</p>
                        <p>Als eine Aussicht wehen</p>
                        <p>Sonne Herbstes weit, die Lüffte wehen</p>

                        <br />
                        <p>Die Zweig’ und Äste durch mit frohem Rauschen</p>
                        <p>
                          Wenn schon mit Leere sich die Felder dann vertauschen,
                        </p>
                        <p>Der ganze Sinn des hellen Bildes lebet</p>
                        <p>Als wie ein Bild, das goldne Pracht umschwebet.</p>

                        <br />
                        <p>d. 15ten Nov.</p>
                        <p>1759.</p>
                      </Poem>
                    </Stack>
                  </Box>
                </Stack>
                <Stack space={6}>
                  <StepsBox step={2}>
                    <Paragraph>
                      Nun wird's farbig: Male zuerst den Baustamm mit einem
                      Pinsel auf das Papier.
                    </Paragraph>
                  </StepsBox>
                  <Image
                    image={data.baum1.childImageSharp.gatsbyImageData}
                    alt="Schritt 1"
                  />
                </Stack>
                <Stack space={6}>
                  <StepsBox step={2}>
                    <Paragraph>
                      Für die Baumkrone brauchen wir deine Handfläche: Bemale
                      sie mit brauner Tempera-, Acryl- oder Wasserfarbe.
                    </Paragraph>
                  </StepsBox>
                  <Image
                    image={data.baum2.childImageSharp.gatsbyImageData}
                    alt="Schritt 2"
                  />
                </Stack>
                <Stack space={6}>
                  <StepsBox step={3}>
                    <Paragraph>
                      Setze die Handfläche über dem Baumstamm an und drücke
                      deine Handfläche auf das Blatt Papier. Falls dabei noch
                      ein paar Lücken übrig bleiben, kannst du deine Finger zur
                      Hilfe nehmen, um die Lücken auszufüllen.
                    </Paragraph>
                  </StepsBox>
                  <Image
                    image={data.baum3.childImageSharp.gatsbyImageData}
                    alt="Schritt 3"
                  />
                  <Image
                    image={data.baum4.childImageSharp.gatsbyImageData}
                    alt="Schritt 4"
                  />
                </Stack>
                <Stack space={6}>
                  <StepsBox step={3}>
                    <Paragraph>
                      Anschließend wäscht du deine Handfläche gründlich mit
                      Wasser und Seife und wartest kurz, bis die Farben von
                      Baumstamm und Krone getrocknet sind.
                    </Paragraph>
                  </StepsBox>
                  <Image
                    image={data.baum5.childImageSharp.gatsbyImageData}
                    alt="Schritt 5"
                  />
                </Stack>
                <Stack space={6}>
                  <StepsBox step={5}>
                    <Paragraph>
                      Nun fehlen nur noch die farbigen Blätter. Dafür verwendest
                      du deine Finger. Tauche sie in verschiedene Farben ein
                      oder verwende einen Pinsel, um die Farbe auf deinen Finger
                      zu bekommen.
                    </Paragraph>
                  </StepsBox>
                  <Image
                    image={data.baum6.childImageSharp.gatsbyImageData}
                    alt="Schritt 6"
                  />
                  <Image
                    image={data.baum7.childImageSharp.gatsbyImageData}
                    alt="Schritt 7"
                  />
                </Stack>
                <Stack space={6}>
                  <StepsBox step={5}>
                    <Paragraph>
                      Verleihe dem Baum ein herbstlich buntes Gewand. Wasche
                      deine Hände anschließend gründlich mit Wasser und Seife.
                    </Paragraph>
                  </StepsBox>
                  <Image
                    image={data.baum8.childImageSharp.gatsbyImageData}
                    alt="Schritt 8"
                  />
                  <Image
                    image={data.baum9.childImageSharp.gatsbyImageData}
                    alt="Schritt 9"
                  />
                </Stack>
                <StepsBox step={8}>
                  <Paragraph>
                    Zuletzt schneidest du den Baum aus und klebst ihn gemeinsam
                    mit Hölderlins Herbstgedicht in deinen Kalender.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Grid columns={[1, 2]} space={3}>
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Detailansicht der fertigen Kalenderseite. Bunte Buchstaben und ein handgeschriebenes Gedicht"
              />
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Ein weitere Detailansicht der fertigen Kalenderseite. Der Buchstabe E ist fokussiert"
              />
            </Grid>
          </Stack>

          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
